import {api} from "@/bootstrap/api";
import {GetCarrierListResponseSchema} from "@/api/responses/getCarrierListResponse";

type Container = {
  type: 'package-container',
  id: string
}

export type CreateCarrierCollectionRequestType = {
  data: {
    type: 'carrier-collection',
    attributes: {
      carrier_id: string;
      collection_reference: string;
    },
    relationships: {
      packageContainers: {
        data: Container[]
      }
    }
  }
}

export default {
    async dispatchToCarrier(containerIds: number[], carrierId: string, collectionRef: string) {
        const packageContainers: Container[] = containerIds.map(containerId => ({
            type: "package-container",
            id: String(containerId)
        }));
        const payload: CreateCarrierCollectionRequestType = {
          data: {
            type: "carrier-collection",
            attributes: {
              carrier_id: carrierId,
              collection_reference: collectionRef
            },
            relationships: {
              packageContainers: {
                data: packageContainers
              }
            }
          }
        };

        return api(
            'dispatchToCarrier',
            {
                data: payload,
            }
        )
    },
    async getCarrierList(siteId: number) {
        const response = await api(
          'getCarrierList',
          {
              params: {
                  'filter[site_id]': siteId
              },
          }
        );

        return GetCarrierListResponseSchema.parse(response.data.data);
    }
}
